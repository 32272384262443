.boardContainer {
    display: flex;
    align-items: flex-start;
    transform: translateY(56px);
}
.boardColumnWrap {
    width: 240px;
    height: 100%;
}
.boardColumn {
    display: flex;
    flex-direction: column;
    width: 240px;
    padding: 0px 5px;
    height: 100%;
}
.boardColumnHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    position: sticky;
    top: 0;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    border-bottom: 1px solid rgb(228, 228, 228);
    color:rgba(0, 0, 0, 0.35)
}
.boardColumnTitle {
    flex-grow: 1;
    color: #333;
    font-size: .8rem;
    font-weight: 700;
}
.boardColumnHeader input {
    padding: 0;
    font-size: .8rem;
    font-weight: 700;
    letter-spacing: -.02rem;
}
.boardColumnHeader fieldset {
    border: unset;
}
.boardColumnHeader button {
    color:rgba(0, 0, 0, 0.35)
}
.boardColumnLength {
    font-size: .8rem;
}
.MuiCard-root.card {
    padding: 6px;
    margin: 0 6px 4px 6px;
    border-radius: 2px;
    font-size: .75rem;
    flex-shrink: 0;
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
}
.MuiCard-root.card .cardChip {
    margin-top: 2px;
    border-radius: 2px;
    font-family: inherit;
    font-size: inherit;
    height: auto;
}
.MuiCard-root.card .cardChip .MuiChip-label {
    padding-left: .25rem
}
.MuiCard-root.card .cardCommentIcon {
    height: 1rem;
    width: 1rem;
    float: right;
    color: rgba(0, 0, 0, 0.35)
}
.MuiCard-root.card .cardLockIcon {
    height: 1rem;
    float: right;
    color: rgba(0, 0, 0, 0.35)
}
.MuiCard-root.card .CardCompanyNameWrap {
    display: flex;
    overflow: hidden;
}
.MuiCard-root.card .cardCompanyName {
    flex-shrink: 0;
    font-size: .65rem;
    color: rgba(0, 0, 0, 0.67);
    border: 1px solid rgb(221, 221, 221);
    border-radius: 2px;
    padding: 1px 2px;
}
@media only screen and (min-width: 600px) {
    .boardContainer {
        transform: translateY(65px);
    }
}
@media only screen and (max-width: 1241px) {
    .boardContainer {
        display: block;
    }
    .boardColumn, .boardColumnWrap {
        width: 100vw;
    }
}