
:root {
    --main: #0971f1;
    --darker: #053e85;
    --neutral: #64748B;
    --contrastText: #fff;
    --backgroundSecondary: #F8F9FA;
}
body, label, input, button, .button, textarea, select, :root .MuiInput-input, .MuiInput-input textarea, .MuiGrid-root .MuiAutocomplete-input {
    text-rendering: optimizeLegibility;
    font-size: .85rem;
}
.authWrap {
    display: flex;;
}
.authContainer {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.MuiAppBar-root.appBar {
    box-shadow: none;
    border-bottom: 1px solid #ccc;
    z-index: 1;
}
.appBar .userMenu button,
.appBar .notificationsMenu button,
.MuiIconButton-root:hover {
    color: var(--neutral)
}
.modalPrimary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    max-width: 980px;
    background-color: #fff;
    padding: 16px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 15%);
    border-radius: 4px;
}
.modalPrimary label {
    color: rgba(0, 0, 0, 0.35)
}