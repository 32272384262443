.MuiTableCell-root {
  padding: 4px !important;
  font-size: 1rem !important;
  border-bottom: 0 !important;
}
.times-table .MuiTableCell-root {
  padding: 0 !important;
}
.times-table .MuiTableRow-root .MuiTableCell-root:first-child {
  width: 135px !important;
}
